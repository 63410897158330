import getCookie from './getCookie.js'

const enPathnameRegex = /^\/en\//
const getLocale = () => {
    let locale = getCookie('uicult2')
    if (enPathnameRegex.test(window.location.pathname)) {
        locale = 'en'
    }
    return locale
}

export default getLocale;