import getLocale from './utils/getLocale.js';
const locale = getLocale() || 'ru';

const strings = {
  ru: {
    hour: 'в час',
    day: 'в день',
    shift: 'за смену',
    contract: 'договорная',
    km: 'км',
    workingHours: 'м.ч.',
    ton: 'т',
    meter: 'м',
    year: 'г',
    rub: 'руб',
    addAdvert: 'Добавить объявление',
    free: 'бесплатно',
    truckmarket: 'Тракмаркет',
    rent: 'Аренда',
    and: 'и',
    sale: 'продажа',
    ofTransport: 'транспорта',
    ofSpecialMachinery: 'спецтехники',
    allAds: 'Все объявления',
  },
  en: {
    hour: 'per hour',
    day: 'per day',
    shift: 'per shift',
    contract: 'negotiable',
    km: 'km',
    workingHours: 'e.h.',
    ton: 't',
    meter: 'm',
    year: 'y',
    rub: 'rub',
    addAdvert: 'Add new',
    free: 'for free',
    truckmarket: 'Truckmarket',
    rent: 'Rent',
    and: 'and',
    sale: 'sale',
    ofTransport: 'of vehicles',
    ofSpecialMachinery: 'special machinery',
    allAds: 'All advertisements',
  }
}

export default strings[locale];