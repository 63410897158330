<script>
	import { onMount } from 'svelte';
	import getAdverts from './api/getAdverts.js';
	import variables from './variables.js';
	import strings from './strings.js';

	import FullCard from './components/FullCard.svelte'
	import Link from './components/Link.svelte'
	import Badge from './components/Badge.svelte'
	import AddAdvertButton from './components/AddAdvertButton.svelte'
	import MoreButton from './components/MoreButton.svelte'

	let adverts = [];
	const countAdverts = 4;

	let width;
 	let id = `tm${Math.floor(Math.random()*1e6).toString(16)}`;

	$: isFourCards = width >= variables.withFourCards;
	$: isThreeCards = width < variables.withFourCards && width >= variables.withThreeCards;
	$: isTwoCards = width < variables.withThreeCards && width >= variables.withTwoCards;
	$: isVerticalWidget = width < variables.withTwoCards && width > 0;
	$: isEmptyWidget = (adverts && adverts.length === 0) || width === 0;

	let calcWidth = () => {
		const elementContainer = document.querySelector(`.${id}`);
		if (elementContainer) {
			width = elementContainer.offsetWidth || 0;
		}
	};
	onMount(async () => {
		window.addEventListener('resize', calcWidth);
		calcWidth();
		adverts = await getAdverts(countAdverts);
	});

</script>

<div data-app="truckmarket-widget" class="widget-container {id}">
	<div class="truckmarket-widget"
		class:four-cards={isFourCards}
		class:three-cards={isThreeCards}
		class:two-cards={isTwoCards}
		class:vertical-widget={isVerticalWidget}
		class:empty-widget={isEmptyWidget}
	>
		<div class="header-block">
			{#if isVerticalWidget}
				<div class="tm-title">{strings.truckmarket}</div>
			{/if}
			<div class="header">
				<div class="text">
					<Link name={strings.rent} href={`https://${variables.host}/trucks/rent${variables.utm}`} black={true}/> {strings.and}&nbsp;<Link name={strings.sale} href={`https://${variables.host}/trucks${variables.utm}`} black={true}/>
					{strings.ofTransport} {strings.and}&nbsp;{strings.ofSpecialMachinery}
				</div>
				{#if !isVerticalWidget}
					<div class="badge-container"><Badge text="new"/></div>
				{/if}
			</div>
			{#if !isVerticalWidget}
				<AddAdvertButton href={`https://${variables.host}/cabinet/form${variables.utm}`} {isTwoCards}/>
			{/if}
		</div>
		<div class="cards-container">
			{#each adverts as advert}
				<div class="card-container">
					<FullCard {advert} {isVerticalWidget}/>
				</div>
			{/each}
		</div>
		<MoreButton text={strings.allAds} href={`https://${variables.host}/trucks${variables.utm}`}/>
	</div>
</div>

<style>
 .widget-container {
	 width: 100%;
	 height: 100%;
	 line-height: normal;
 }

 .truckmarket-widget {
	 font-family: Verdana, 'Dejavu Sans', Geneva, Tahoma, sans_serif;
	 color: var(--glz-color-neutral-tone-5);
	 display: flex;
	 flex-direction: column;
 }

 .truckmarket-widget.empty-widget {
	 display: none;
 }

 .truckmarket-widget.vertical-widget {
	 width: 100%;
	 box-sizing: border-box;
	 background-color: var(--glz-color-neutral-tone-0);
	 border-radius: 4px;
	 padding: 24px;
	 justify-content: space-between;
 }
 .truckmarket-widget.three-cards,
 .truckmarket-widget.two-cards {
	 box-sizing: border-box;
	 background-color: var(--glz-color-neutral-tone-0);
	 border-radius: 4px;
	 padding: 24px;
 }

 .header-block {
	 display: flex;
	 justify-content: space-between;
 }

 .vertical-widget .header-block {
	 flex-direction: column;
 }

 .tm-title {
	color: var(--glz-color-neutral-tone-4);
	letter-spacing: 0.1em;
	font-size: 13px;
	padding-bottom: 6px;
	 text-transform: uppercase;
 }

 .header {
	 font-size: 26px;
	 display: flex;
	 align-items: flex-end;
 }
 .header :global(a) {
	 font-size: 26px;
 }

 .header .text {
	 white-space: normal;
 }

 .three-cards .header,
 .two-cards .header,
 .vertical-widget .header,
 .three-cards .header :global(a),
 .two-cards .header :global(a),
 .vertical-widget .header :global(a) {
	 font-size: 20px;
 }

 .cards-container {
	 margin-top: 15px;
	 margin-bottom: 25px;

	 display: flex;
	 justify-content: space-between;
 }

 .card-container {
	 flex: 1;
	 max-width: calc(95% / 4);
 }

 .three-cards .card-container {
	 max-width: calc(95% / 3);
 }

 .three-cards .card-container:nth-child(4) {
	 display: none;
 }
 .two-cards .card-container {
	 max-width: calc(95% / 2);
 }
 .two-cards .card-container:nth-child(3),
 .two-cards .card-container:nth-child(4){
	 display: none;
 }
 .two-cards .header-block {
	 align-items: center;
 }

 .vertical-widget .cards-container {
	 flex-direction: column;
	 display: block;
	 margin: 0 0 16px 0;
 }

 .vertical-widget .card-container:nth-child(4) {
    display: none;
}

 .vertical-widget .card-container {
	 max-width: 100%;
	 padding-right: 0;
	 padding-top: 16px;
 }

 .badge-container {
	 margin-left: 12px;
	 padding-bottom: 3px;
 }
 .two-cards .badge-container {
	 display: none;
 }
</style>
