<script>
	import stringifyPrice from '../utils/stringifyPrice.js';
	import variables from '../variables.js';
	import strings from '../strings.js';
	import getLocale from '../utils/getLocale.js';
	import stringifyLongNumber from "../utils/stringifyLongNumber";
	export let advert;
	export let isVerticalWidget = false;

	const isRuLocale = getLocale() === 'ru';

	if (advert.advert_item_type === 'road_train') {
		advert.advert_item = advert.advert_items[0].transport;
	}

	// Use small version of image on vertical widget and middle on horizontal
	let urlParts = advert.photo_url.split("/");
	let imgPrefix = isVerticalWidget ? variables.smImgPrefix : variables.mdImgPrefix
	let imgFilename = imgPrefix + urlParts.slice(-1)[0];
	let urlWithPrefix = urlParts.slice(0,-1).concat(imgFilename).join("/");

	$: title = isRuLocale ? `${advert.advert_item.brand.name} ${advert.advert_item.model.model}` : advert.advert_item.brand.name_eng;
	$: src = `https://${variables.host}${urlWithPrefix}`;
	$: url = advert.url;
	$: price = advert.price && stringifyPrice(advert.price);
	$: currency = advert.currency;

	$: capacity = advert.advert_item.capacity;
	$: volume = advert.advert_item.volume;
	$: year = advert.advert_item.year;
	$: mileage = advert.advert_item.mileage;
	$: textMillage = strings.km;
	$: workingHours = advert.advert_item.working_hours;
	$: textWorkingHours = strings.workingHours;
	$: period_type = advert.period_type ? variables.periodTypes[advert.period_type] : null;
	$: city = advert.city_verbose;

	$: if (mileage) {
		const [num, text] = stringifyLongNumber(mileage, strings.km);
		mileage = num;
		textMillage = text;
	}

	$: if (workingHours) {
		const [num, text] = stringifyLongNumber(workingHours, strings.workingHours);
		workingHours = num;
		textWorkingHours = text;
	}

	// TODO: выпилить эти костыли и разобраться с эллипсом
	$: if (title.length > 28) {
		title = title.slice(0, 25) + '...';
	}
	$: if (city.length > 28) {
		city = city.slice(0, 25) + '...';
	}
</script>

<div class="full-card"
	 class:vertical-widget={isVerticalWidget}
	 on:click={() => window.open(`https://${variables.host}${url}${variables.utm}`,'_blank')}
>
	<img src={src} alt="Truckmarket">
	<div class="parameters-container">
		<div class="truck-name" class:en-truck-name={!isRuLocale}>{title}</div> 
		<div class="truck-parameters">
			{#if capacity || capacity === 0}
				<span class="param">{capacity} {strings.ton}.,</span>
			{/if}
			{#if volume || volume === 0}
				<span class="param">{volume} {strings.meter}<sup>3</sup>,</span>
			{/if}
			{#if year}
				<span class="param">{year} {strings.year}.{(mileage || mileage === 0 || workingHours || workingHours === 0) && ',' || ''}</span>
			{/if}
			{#if mileage || mileage === 0}
				<span class="param">{mileage} {textMillage}</span>
			{:else if workingHours || workingHours === 0}
				<span class="param">{workingHours} {textWorkingHours}</span>
			{/if}
		</div>
		<div class="city">
			{city}
		</div>
		<div class="price">
			{#if price || price === 0}
				<b>{price}</b> {currency === 'rub' ? `${strings.rub}.` : `${currency}.`}{ period_type && `/${period_type}` || ''}
			{/if}
		</div>
	</div>
</div>

<style>
	.full-card {
		 font-family: Verdana, 'Dejavu Sans', Geneva, Tahoma, sans_serif;
		 width: 100%;
		 display: flex;
		 flex-direction: column;
		 cursor: pointer;
	}

	.full-card:hover {
	 	opacity: 0.8;
	}

	img {
		display: block;
		width: 100%;
		object-fit: cover;
	}

	.truck-name {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 2px;
	}

	.truck-name.en-truck-name {
		text-transform: capitalize;
	}

	.parameters-container {
		font-size: 12px;
		padding-top: 7px;
		display: flex;
		flex-direction: column;
	}

	.param {
		white-space: nowrap;
	}

	.price {
		padding-top: 4px;
	}

	.city, .price, .parameters-container, .truck-parameters {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 100%;
	}

	.param {
		text-overflow: ellipsis;
	}

	.truck-parameters, .city {
		color: var(--glz-color-neutral-tone-4);
		padding-top: 2px;
	}

	.vertical-widget {
		width: 100%;
		height: 68px;
		flex-direction: row;
	}

	.vertical-widget img {
		width: 80px;
		height: 100%;
		object-fit: cover;
	}

	.vertical-widget .truck-name {
		font-size: 12px;
	}

	.vertical-widget .truck-parameters {
		padding-top: 0;
	}

	.vertical-widget .parameters-container {
		padding-top: 0px;
		padding-left: 8px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: calc(100% - 88px);
	}

	.vertical-widget .truck-name {
		text-overflow: ellipsis;
    	white-space: nowrap;
    	overflow: hidden;
	}
</style>