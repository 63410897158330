<script>
	export let text = '';
</script>

<span class="badge">{text}</span>

<style>
  .badge {
    padding: 4px 8px;
    font-size: 11px;
    background-color: var(--glz-color-success);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 2px;
  }
</style>