import Widget from './Widget.svelte';

window.renderTmWidget  = (className = 'tm-widget') => {
  const elements = document.querySelectorAll(`.${className}`);
  elements.forEach(element => {
    element.innerHTML = '';
    new Widget({
      target: element,
    })
  });
};

// Для разработки

// const widget  = new Widget({
//   target: document.querySelector('.tm-widget'),
// })

// export default widget;