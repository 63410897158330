<script>
  import Link from './Link.svelte';
  import strings from '../strings.js';

	export let href = '';
	export let isTwoCards = false;
</script>

<div class="add-button" class:two-cards={isTwoCards}>
  <a {href} target="blank" class="icon-link">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0235 12.9859V11.0141H12.9859V6.97653H11.0141V11.0141H6.97653V12.9859H11.0141V17.0235H12.9859V12.9859H17.0235ZM12 2C14.7543 2 17.1095 2.97808 19.0657 4.93427C21.0219 6.89046 22 9.24568 22 12C22 14.7543 21.0219 17.1095 19.0657 19.0657C17.1095 21.0219 14.7543 22 12 22C9.24568 22 6.89046 21.0219 4.93427 19.0657C2.97808 17.1095 2 14.7543 2 12C2 9.24568 2.97808 6.89046 4.93427 4.93427C6.89046 2.97808 9.24568 2 12 2Z" fill="var(--glz-color-primary-tone-1)"/>
    </svg>
  </a>
  <div class="text-container">
    <span class="sub-text">{strings.free}</span>
    <Link name={strings.addAdvert} {href} />
  </div>
</div>

<style>
  .add-button {
    font-size: 16px;
    display: flex;
    align-items: flex-end;
  }
  .add-button :global(a) {
    font-size: 16px;
  }

  .add-button.two-cards {
    /*width: 120px;*/
    display: flex;
    align-items: flex-end;
  }

  .icon-link {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 7px;
    box-sizing: content-box !important;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 3px;
  }

  .sub-text {
    font-size: 11px;
    color: var(--glz-color-neutral-tone-5);
    opacity: 0.5;
  }

  .two-cards .icon-link,
  .two-cards .icon-link svg {
    height: 32px;
    width: 32px;
  }
  .two-cards .text-container {
    display: none;
  }
</style>