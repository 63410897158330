const advert = {
  advert_item: {
    blade_width: 12,
    brand: { id: 99, name: 'КамАЗ asda sdasdasd', name_eng: 'KamAZ' },
    model: { model: '65115 asda sd sd sdasdasdasd asdasd asddsad asd asdasd' },
    central_oil_management: true,
    computer: true,
    conditioner: true,
    drive_type: '1',
    engine_power: 1,
    engine_preheater: true,
    engine_type: '1',
    engine_volume: 1,
    fuel_consumption_100: 11,
    fully_weight: 12,
    gearbox_type: '1',
    gov_num: '222222222222',
    ground_pressure: 12,
    is_new: false,
    max_blade_position_height: 12,
    max_cutting_depth: 12,
    max_loosening_depth: 12,
    max_speed: 11,
    right_side_helm: true,
    tank_volume: 11,
    tanks_count: 1,
    tracks_width: 12,
    traction_class: '15',
    vin: '222222222',
    wheel_formula: '1',
    working_hours: 1111,
    year: 1111,
    volume: 30,
    capacity: 95,
    mileage: 9500
  },
  url: '/sale/truck/avia/a-20f-a214a21fdv712-181/1589288314',
  photo_url: '/files/photos/2020/06/04/2397625/dbjs128d.jpg',
  price: 10300800,
  currency: 'rub',
  period_type: 'contract'
};

export default advert;
