const stringifyPrice = price => {
  const arr = `${price}`.split('');
  return arr
    .reverse()
    .map((item, index) => {
      if (!(index % 3)) return `${item} `;
      return item;
    })
    .reverse()
    .join('');
};

export default stringifyPrice;
