const getEnvironDomain = () => {
    if (typeof window === "undefined") return 'ati.su';

    if (window.atiEnv) return window.atiEnv.baseDomain;

    return (
        window.location && window.location.hostname.split(".").slice(-2).join(".")
    );
};

export default getEnvironDomain;