import strings from './strings.js'
import getEnvironDomain from "./utils/getEnvironDomain";
const host = `tm.${getEnvironDomain()}`;
const cardPaddingRight = 26;
const cardWidth = 248;
const withWidgetColumn = 332;
const periodTypes = {
    'hour': strings.hour,
    'day': strings.day,
    'shift': strings.shift,
    'contract': strings.contract,
};
const mdImgPrefix = 'md_'; // medium thumbnail
const smImgPrefix = 'sm_'; // small thumbnail
const utm = '?utm_medium=tmwidget'

const variables = {
  host,
  cardPaddingRight,
  cardWidth,
  withWidgetColumn,
  withFourCards: cardWidth * 4 + cardPaddingRight * 3,
  withThreeCards: cardWidth * 3 + cardPaddingRight * 2,
  withTwoCards: cardWidth * 2 + cardPaddingRight,
  periodTypes,
  mdImgPrefix,
  smImgPrefix,
  utm
};

export default variables;
