import getLocale from './getLocale.js';

const texts = {
  ru: {
    million: 'млн',
    thous: 'тыс',
  },
  en: {
    million: 'mln',
    thous: 'thous',
  },
};

const locale = getLocale() || 'ru';

/**
 * Функция сокращения числового значения
 * Возвращает массив ['сокращенное число', 'единица измерения']
 * @param  {nubmer || string} longNumber - исходное число
 * @param  {string} unit - единица измерения (например 'км' или 'м.ч')
 */
const stringifyLongNumber = (longNumber, unit) => {
  const number = parseInt(longNumber, 10);

  const thousand = 1000;
  const tenThousand = 10000;
  const oneHundredThousand = 100000;
  const million = 1000000;

  let result = [];

  if (number >= million) {
    let float = number / million;
    float = parseFloat(float.toFixed(1));
    result = [`${float.toString().replace('.', ',')}`, `${texts[locale].million}. ${unit}`];
  } else if (number >= oneHundredThousand) {
    let float = number / thousand;
    float = parseFloat(float.toFixed(0));

    if (float >= thousand) {
      float = parseFloat((float / thousand).toFixed(1));
      result = [`${float.toString().replace('.', ',')}`, `${texts[locale].million}. ${unit}`];
    } else {
      result = [`${float.toString().replace('.', ',')}`, `${texts[locale].thous}. ${unit}`];
    }
  } else if (number >= tenThousand) {
    let float = number / thousand;
    float = parseFloat(float.toFixed(1));
    result = [`${float.toString().replace('.', ',')}`, `${texts[locale].thous}. ${unit}`];
  } else if (number >= thousand) {
    const arr = number.toString().split('');
    result = [arr.map((item, i) => (i === 0 ? `${item}\u00A0` : item)).join(''), unit];
  } else result = [`${number}`, unit];

  return result;
};

export default stringifyLongNumber;
