<script>
	export let text = '';
	export let href = '';
</script>

<div class="more-button" on:click={() => window.open(href,'_blank')}>
  <span class="text">
    {text}
  </span>
</div>

<style>
  .more-button {
    font-size: 14px;
    height: 42px;
    background-color:hsla(0,0%,72%,.3);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
  }

  .more-button:hover {
    background-color:hsla(0,0%,72%,.4);
  }

  .text {
    opacity: 1;
  }
</style>