<script>
	export let name = '';
	export let href = '';
	export let black = false;
</script>

<a {href}
   class="tm-link"
   class:tm-black-link={black}
   target="_blank">{name}</a>

<style>
  .tm-link,
  .tm-link:hover,
  .tm-link:active,
  .tm-link:visited {
    text-decoration: none;
    color: var(--glz-color-primary);
  }

  .tm-link:hover {
    color: var(--glz-color-primary-tone-1);
  }

  .tm-black-link,
  .tm-black-link:active,
  .tm-black-link:visited {
    color: var(--glz-color-neutral-tone-5);
    text-decoration: none;
    border-bottom: 1px solid var(--glz-color-neutral-tone-3);
  }

  .tm-black-link:hover {
    color: var(--glz-color-primary-tone-1);
    border-bottom: 1px solid var(--glz-color-primary-tone-1);
  }
</style>