import vars from '../variables.js';
import mockAdvert from '../mocks/advert.js';

const isDev = window.location.host.indexOf('localhost') !== -1;

const getAdverts = async (count = 4) => {
  let res; 
  const response = await fetch(`https://${vars.host}/public/api/v1/transports/widget`, {
    method: 'POST',
    body: JSON.stringify({ count }),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json, text/plain, */*',
    },
    credentials: 'include'
  }).catch(() => {
    return isDev ? [mockAdvert, mockAdvert, mockAdvert, mockAdvert] : [];
  });

  if (response['ok']) {    
    const result = await response.json();
    res = result.result.adverts.slice(0, count);
  } else {
    return isDev ? [mockAdvert, mockAdvert, mockAdvert, mockAdvert] : [];
  }

  return res;
};

export default getAdverts;
